import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
import Video from "../../components/Video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "493px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/77cdd210d28efc4d4235ef09d2f20292/f88d0/dq4-title.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.1891891891892%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC+0lEQVQ4y4WSyU8TURzHHwHighdAllJKF2kCAoVSgxcNJv4DejReNAbByEFDuMgiuyTe1ED0QNnazj7T2VoWEeXCSRMoUCiLhoMJbSnImmlpfV2Bi04+85vv+858561A0KsgXKX6DJqE5s/5ak4fM6MpIOoU9O3y4e62ofYWY1vzYHtLmI6WmDilGZojna3G3q6Rh/dtpXnwF8CmyyfuGMw0Y7XZIbRoY0QbLYhW+xjFC0zEDGMfI3lBnJhAxyYHntfYi3N4gyYSrtabzCYUQQgMIwmcJkkcwygSShzecUFYzCYSwyw0M1D7yHY9NxGuRODXFIVhGB6/CILgOI5hGCgSJonjJooeqHt8NqxHEISCL1E0AgIrarEQKApHAYcDm1DjCEqi6ChOGE97LoNzrrQwDM0LBMvC7gg2DG5lSY5nRDHahD7OshTLmXjRWF9ji84ZrjZ1SzfU1W7p7TL1dEDMbzoho93tCZ3wLT0dI297hx/ci612eMcqVEKZ/N+IZXK2JI/TKYRSuVBeENvn6EMwaP4B7GSsSmsskvcXyuxVWt5QeD78P5Ay5Qd1dtvVtP5rue9VWZ8Kc+yxYUPgiYsfulNHr+L1artBTeqUH7W5rZmXG9KSmzIuNVxJeZV+kU/0zOsKODiZeJKvUHIleRFfIery3xVk1KeA2mRQlwKeAvAsFTwBoE+RDtgSGVuUK97QjFfroIAZtlgGM5N39dZimXhT21ekeJEEWpRZjWkXGtNSXyuzXyaBJll6T34m8Mwg7mlk54e475pxfyM8M2bPDL49yxyuTm1NY7uLX72OqU374NHq5O9p89Yse+D6vDmB7jm/bM+Ng+CeM7gPWQ94v4cOXMGjjeDh2skf58m2I3j8K7TnCu46QsfrJ76F0NFG6HD9ZGcpdOAM7kIWgbQ1J3kW/J4lv9sBa2Dnp+RZktyLfveCtL0iuef9sHpdfu+S5HFKnsWw2Jr3+9Yk7zII7LgirIbxLQd8K3G94oe+zxXzI85pDUdW/gJ5eUpeNSyGoAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dragon Quest Title",
            "title": "Dragon Quest Title",
            "src": "/static/77cdd210d28efc4d4235ef09d2f20292/f88d0/dq4-title.png",
            "srcSet": ["/static/77cdd210d28efc4d4235ef09d2f20292/a2ead/dq4-title.png 259w", "/static/77cdd210d28efc4d4235ef09d2f20292/f88d0/dq4-title.png 493w"],
            "sizes": "(max-width: 493px) 100vw, 493px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Inspired by Jason Schreier and his Final Fantasy Retrospective series, I figured I'd do something similar for one of my other favorite JRPG series of all time: Dragon Quest. Unlike his series though, I'm going to go out of order, picking and choosing my battles. There is no better place to start than perhaps the best game in the series, Dragon Quest 4.  `}</p>
    <p><strong parentName="p">{`Other articles in the series:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../DragonQuest1"
        }}>{`Dragon Quest 1`}</a></li>
    </ul>
    <h2>{`Story`}</h2>
    <p>{`Unlike the other games in the series, this game splits out the party you assemble across the chapters letting each of them get a little screen time and character building before letting you gather them all up in the final chapter to bring down the big baddy. The game starts out with you in control of Ragnar, a stoic soldier from the country of Burland. It turns out that children have been disappearing and it's up to you to figure out what's going on. From here you move on to Alena, Cristo, and Brey. Alena, a princess, wants to escape the confines of the castle and her station and see the world. Cristo and Brey, her two advisors agree to join her and protect her along the way. After this story concludes we head to Lakanaba where we meet Taloon, a merchant who desires nothing more than being the worlds greatest merchant. He eventually brokers a peace between two countries and opens a shop and moves his family to the larger of the two kingdoms, Endor. The final chapter before we're introduced to the Hero, introduces us to two sisters, one a dancer the other a fortune teller, who embark on a quest to avenge the murder of their father by his assistant.`}</p>
    <p>{`In each of these chapters we get whispers of things like the Hero, alchemy, evolution, and the upcoming resurrection of the lord of monsters. Finally we are introduced to the Hero, who has been sheltered in a remote village raised by step parents. As the chapter starts the town is ransacked by monsters, the village is wiped out defending you while you hide in a barrel of fruit, and your best friend sacrifices themselves to trick the monsters into thinking they've killed you. From there, you head out into the world, discover your father almost immediately, find out your mother was an angel, and slowly put together the rest of the pieces of the puzzle surrounding Estark and Saro. Turns out Estark used alchemy and the secret of evolution to turn into the "ultimate life form," however he was defeated and sealed away by the Zenith Dragon. After you crush him beneath the mines of Aktemto, Saro follows suit, using the same tricks to become Necrosaro until you eventually put him down.`}</p>
    <p>{`This story was a big leap forward for the series. Prior to this, your Hero was essentially pointed at the endgame boss by the king of the starting castle and you fulfilled some side quests along the way. Dragon Quest 3's story had a little more nuance, but nothing like this game.`}</p>
    <h2>{`Combat`}</h2>
    <p>{`For the most part this is standard Dragon Quest fare. Each character has the classic HP/MP pools, and each has their own stat growth rates. That being said there are a few big additions here. The first is the wagon, an item you acquire along that way that allows you to bring more than 4 characters along with you. Having access to this allows you to change your party in between rounds mid-combat. It's always available to you in the overworld, and in certain dungeons as well. This lets you switch out characters to save MP, or swap in fresh characters when the need arises. Furthermore, so long as the wagon is with you, characters in it receive XP even if they didn't contribute to the battle at all. The second big change allows you to configure the computer to control every main character but yourself. I personally found this sub-optimal and never used it. Additionally there are a few extra characters you add to your party at various points in the game that can never be controlled.`}</p>
    <h2>{`Music`}</h2>
    <p>{`Call it nostalgia, but personally I think this is Koichi Sugiyama's best work overall. So much so that, and again this may be confirmation bias, in all the random spin-offs games that have been released since (e.g. Dragon Quest Heroes, Dragon Quest Builders) the tracks here seem to get re-used the most. We don't need to mention the Dragon Quest Overture, a truly classic piece of video game music, but other standouts include: the town theme, Frightening dungeon, Endor Casino, all of the colosseum music, Gypsy's journey, Gypsy's dance and the Wagon Wheel's March. There are more, but these are the highlights.`}</p>
    <Video videoSrcUrl='https://www.youtube.com/embed/6j7h2cDy1H4' videoTitle='Wagon Wheels March' mdxType="Video" />
    <h2>{`Lasting Impact`}</h2>
    <p>{`This game in the series had a few changes that would go on to be implemented throughout the rest of the series`}</p>
    <ul>
      <li parentName="ul">{`This was the first time you could bring more than four characters with you. In Dragon Quest 3 you could have dozens of characters but only four could come with you and gain XP.`}</li>
      <li parentName="ul">{`This was the first time you could pass off control of your characters to the computer.`}</li>
      <li parentName="ul">{`This was also the first time the story got truly ambitious not just with plot twists, but with character development as well. It is nothing on the scale of a Final Fantasy, and the protagonist remains mute throughout (a series staple), but starting from here we get real storylines and not just pointing the Hero at the final boss like a notched arrow and letting go. Healie becomes human!`}</li>
      <li parentName="ul">{`Taloon. This character was so popular he got his own set of games, kickstarting the Mystery Dungeon series. He's also a playable character in Dragon Quest Heroes 2.`}</li>
    </ul>
    <h2>{`Remake`}</h2>
    <p>{`I'll go on record overall as stating that for the most part, across all remakes in the series, I am not a fan of the re-localization. I don't need to hear the populous of Burland speak to me in their bad Scottish accents. This remake was also, if not the first then one of the earliest, to make the jump to 3D. Not only that, it added a whole new chapter at the end of the game where we could recruit Psaro (newly localized name). These additions/changes are...fine. The post game dungeon did become a staple starting with DQ5 and it's nice we get a new character during it, but it doesn't really add much to the experience. Outside of maybe testing the DQ7 engine on this game, I can't really say the 3D adds much either. There's nothing hidden behind a wall that wasn't hidden there before. The best I can say is that none of these updates detract from the core experience. Not like the original remake of DQ3...but that's a tale for another article.`}</p>
    <h2>{`Does It Hold Up?`}</h2>
    <p>{`Absolutely. No matter which version you can get ahold of, you owe it to yourself to play this masterpiece of a game. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      